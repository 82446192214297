import {join, setMeta} from './helpers'
export default function Connector({element, type, onRouteChange}){
  return async function({id, url, params, content}){
    if(!window._SSR && url === window.location.pathname){
      console.log('not re-rendering location', url, '=', window.location.pathname);
      return 
    }
    if(content){
        element.innerHTML = `<div>${content.body}</div>`
        setMeta(content.meta || {})
        return
    }
    const pth = join(window.location.origin, '/public/content', `${type}-${id}.json`)
    var {meta, title, wrap, rendered} = await fetch(pth).then(r => r.json())
    setMeta(meta, title)
    window.scrollTo && window.scrollTo(0, 0);
    if(meta.hide_menu !== false){
      document.body.classList.add('nonav')
    }
    else {
      document.body.classList.remove('nonav')
    }
    element.innerHTML = `<div class="${wrap}">${rendered}</div>`
    if(onRouteChange){
      onRouteChange({id, url, params, content})
    }
  }
}
