import App from './src/app'
import {Imagator} from './src/imagator'
import Animator from './src/animator'
import animations from './src/animations'
import { qs, qa } from './src/helpers'
import components from '../components'
import {define} from 'wicked-elements'
//import Rellax from 'rellax'
import {html, render} from 'lit-html'
import {VolumeSVG, VolumeSlashSVG} from './src/svg'

let birdAudio = new Audio()

document.addEventListener("DOMContentLoaded", function(event) {
  try {
    birdAudio.src = '/public/audio/birds.mp3'
    birdAudio.autoplay = true
  } catch {
    console.log('cannot autoplay birds');
  }
  let animator = new Animator({actions: animations})
  let imagator = new Imagator({start: true})
  //let rellax
  Object.keys(components).forEach(n => {
    const component = components[n]
    define(component.tagname, component)
  });
  // SETUP APP
  let app = App({
    mount: '#app-mount',
    routesUrl: '/public/content/routes.json',
    onRouteChange
  })
  //.then(() => {
    // setTimeout(function () {
    //     rellax = new Rellax('.rellax')
    // }, 50);
  //});
  appendVolumeToggle()
  //app.run(window.location.pathname || '/')
  // SET UP MOBILE MENU
  qs('#mobile-menu-toggle').addEventListener('click', (e) => {
    e.preventDefault()
    qs('body').classList.toggle('main-nav-visible')
  });
  qa('.top-nav-links a').forEach(a => {
    a.addEventListener('click', (e) => {
      qs('body').classList.remove('main-nav-visible')
    })
  });
  function onRouteChange(){
    // console.log('refreshing rellax !!');
    // setTimeout(function () {rellax = new Rellax('.rellax')}, 50)
  }
});

function appendVolumeToggle(){
  document.body.addEventListener('click', () => {
    console.log('BODY CLICKED');
    birdAudio.play()
    renderAudio()
  }, { once: true })
  var elem = document.createElement('div');
  elem.classList.add('volume-toggle')
  document.body.appendChild(elem)
  function renderAudio(){
    render(html`
      <button @click=${toggleVolume}>
        ${birdAudio.paused? VolumeSVG(): VolumeSlashSVG()}
      </button>
    `, elem)
  }
  function toggleVolume(){
    try {
      birdAudio.paused? birdAudio.play(): birdAudio.pause();
    } catch {
      console.log('failed to play audio..');
    }
    renderAudio()
  }
  toggleVolume()
}
