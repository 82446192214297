import navaid from 'navaid'
function makeRoute({url, id, connector}){
  return function(params){
    connector({url, id, params})
  }
}
export default function({routes, connector}){
  var router;
  if(routes['/404']){
    var {id} = routes['/404'],
    url = '/404'
    router = navaid('/', makeRoute({url, id, connector}))
  }
  else {
    router = navaid('/', makeRoute({connector, content:{
      body: '<h1>404 File Not Found</h1>',
      meta: {title: '404'}
    }}))
  }
  Object.keys(routes).forEach(url => {
    var {id} = routes[url]
    router.on(url, makeRoute({url, id, connector}))
  })
  return router
}
