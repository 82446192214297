import {render, html} from 'lit-html'
import {paypalClientID, ausPostToken} from '../app/settings'
const PaypalButton = {
    tagname: 'paypal-button',
    observedAttributes: [
      'amount', 'address1', 'zip', 'city', 'country','first_name', 'last_name',
      'email', 'custom', 'shipping', 'currency_code', 'weight', 'height',
      'length', 'width', 'return', 'quantity', 'shipping_method'],
    init(){
      this.details = {}
      Array.from(this.element.attributes).forEach(a => {
        this.details[a.name] = a.value
      })
    },
    attributeChanged(name, oldValue, newValue) {
      this.details[name] = newValue
      this.render()
    },
    connected(){
      this.render()
    },
    render(){
      render(html`
<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
<input type="hidden" name="cmd" value="_xclick">
<input type="hidden" name="business" value="LNMQX27WADP8G">
<input type="hidden" name="lc" value="AU">
<input type="hidden" name="item_name" value="Archer And Trevas Book Of Ideas${this.details.custom || ''}">
<input type="hidden" name="item_number" value="atbi">
<input type="hidden" name="button_subtype" value="services">
<input type="hidden" name="no_note" value="0">
<input type="hidden" name="cn" value="Add special instructions to the seller:">
<input type="hidden" name="no_shipping" value="2">
<input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHosted">

<input type="hidden" name="width_unit" value="mm">
<input type="hidden" name="height_unit" value="mm">
<input type="hidden" name="length_unit" value="mm">
<input type="hidden" name="weight_unit" value="kg">
<input type="hidden" name="charset" value="utf-8">
${Object.keys(this.details).map(k => html`<input type="hidden" name="${k}" value="${this.details[k]}">`)}
<input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!">
<img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1">
</form>

      `, this.element)
    }
}

export default PaypalButton
