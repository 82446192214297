const ausPostSeviceURL = window.location.origin + '/auspost.php'
const currencyServiceURL = 'https://free.currconv.com/api/v7/convert?compact=ultra&apiKey=db65c24802d36a58e434'
const currencyCodesURL = window.location.origin + '/public/data/currency.json'
const countriesURL = window.location.origin + '/public/data/countries.json'
export {
  ausPostSeviceURL,
  currencyServiceURL,
  currencyCodesURL,
  countriesURL
}
