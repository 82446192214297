
class Animator {
  constructor({actions}){
    // action options
    // {selector, onIn, onOut, onInit}
    this.selectorMap = {}
    let selectorMap = this.selectorMap
    actions.forEach(a => this.add(a));
    //console.log('MAP', selectorMap);
    let muteObserver = new MutationObserver(mutationCallback);
    let intObserver = new IntersectionObserver(intersectionCallback); //{threshold: [0, 0.25, 0.5]}
    muteObserver.observe(document.body, {childList: true, subtree: true })
    function intersectionCallback(entries, observer){
      entries.forEach(function(entry){
        //console.log(entry.target.className, entry.intersectionRatio);
        let target = entry.target
        let action
        const skeys = Object.keys(selectorMap)
        if(entry.isIntersecting && entry.intersectionRatio > 0){
          //console.log('IS INTERSECTING', target.className, skeys);
            skeys.forEach(s => {
              //console.log('SKEY', s, target.className);
              if(target.matches(s)){
                action = selectorMap[s]
                const minwidth = action.minWidth || window.screen.width
                //console.log(`mw: ${minwidth} sw: ${window.screen.width}`);
                if(window.screen.width >= minwidth)
                  action.onIn && action.onIn(entry.target)
              }
            });
        }
        else {
          // console.log('NOT INTERSECTING');
            skeys.forEach(s => {
              if(target.matches(s)){
                action = selectorMap[s]
                const minwidth = action.minWidth || window.screen.width
                if(window.screen.width >= minwidth)
                  action.onOut && action.onOut(entry.target)
              }
            });
        }
      });
    }
    function mutationCallback(mutationsList, observer){
      if(mutationsList[0].type === 'childList'){
        //console.log('HERE 3');
        mutationsList[0].addedNodes.forEach(n => {
          if(n.tagName){
            Object.keys(selectorMap).forEach(selector => {
              n.querySelectorAll(selector).forEach(ae => {
                const action = selectorMap[selector]
                const minwidth = action.minWidth || window.screen.width
                if(window.screen.width >= minwidth)
                  action.onInit && action.onInit(ae)
                //console.log(ae);
                intObserver.observe(ae)
              })
            })
          }
        })
      }
    }
  }
  add(action){
    console.log('adding', action.selector);
    this.selectorMap[action.selector] = action
  }
}

export default Animator
