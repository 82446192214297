import {render, html} from 'lit-html'
import {ausPostSeviceURL, currencyServiceURL, currencyCodesURL, countriesURL} from '../app/settings'
import './css/buy-book.css'
import {BarLoader} from '../app/src/svg'
const DEFAULT_DOMESTIC_SERVICE = 'AUS_PARCEL_REGULAR'
const DEFAULT_INTERNATIONAL_SERVICE = 'INT_PARCEL_STD_OWN_PACKAGING'
const BuyBook = {
    tagname: 'buy-book',
    init(){
      this.render = this.render.bind(this)
      this.makePaypalButton = this.makePaypalButton.bind(this)
      this.makeAddressForm = this.makeAddressForm.bind(this)
      this.makeButton = this.makeButton.bind(this)
      this.setState = this.setState.bind(this)
      this.updateServices =  this.updateServices.bind(this)
      this.updateCountry =  this.updateCountry.bind(this)
      this.appendError =  this.appendError.bind(this)
      this.validateForm = this.validateForm.bind(this)
      this.calculatePostage = this.calculatePostage.bind(this)
      this.updateExchange = this.updateExchange.bind(this)
      this.updateQuantity = this.updateQuantity.bind(this)
      this.state = {
        showing: 'button'
      },
      this.bookSpecs = {
        height_x: 335,
        length_x: 240,
        width_x: 8,
        weight: 0.4
      }
    },
    connected(){
      this.render()
    },
    async initState(update){
      if(this.state.initialized)
        return
      const countries = await fetch(countriesURL).then(r => r.json())
      const country_code = getCountryCode()
      const country = countries[country_code] // || countries['AU']
      var currencies = country.currency.split(',').pop();
      this.state = {
        showing: 'address',
        country_code,
        language_code: `${country.languages[0]}-${country_code}`,
        country_name: getCountryName(),
        currency_code: getFirstCurrency(country.currency.split(',')),
        services: [],
        countries,
        country,
        serviceName: 'Parcel Post',
        service:'AUS_PARCEL_REGULAR',
        amount: parseFloat(this.element.getAttribute('amount')),
        postage: 0.0,
        formErrors: {},
        errors:[],
        postage_result: {},
        loading: true,
        exchage_rate: 1.0,
        quantity: 1
      }
      if(!window._SSR){
        await this.updateExchange()
        this.setState({initialized: true, loading: false}, true)
      }
      if(update)
        this.render()
    },
    appendError(error, update){
      var {errors} = this.state
      this.setState({errors: [error].concat(errors)}, update)
    },
    async updateCountry(country_name){
      const {countries, currency_codes} = this.state
      if(!countries)
        return
      const country_code = getCountryCodeFromList(country_name, countries, 'AU')
      const country = countries[country_code] || countries['AU']
      const currency_code = getFirstCurrency(country.currency.split(','))
      const language_code = `${country.languages[0]}-${country_code}`
      const exchage_rate = 1.0
      this.setState({country_name, country_code, currency_code, exchage_rate, language_code, country})
      this.updateServices()
      await this.updateExchange()
    },
    async updateExchange(){
      const {country_name, currency_code} = this.state
      this.setState({loading: true})
      const query = `AUD_${currency_code}`
      var result = await fetch(`${currencyServiceURL}&q=${query}`).then(r => r.json())
      //console.log(result);
      if(result && result[query]){
        this.setState({exchage_rate: result[query]})
      }
      else {
        this.appendError({message: 'Failed to get exchange rate for ' + currency_code + ', using AUD'}, true)
        this.setState({exchage_rate: 1.0})
      }
    },
    updateServices(update){
      const {country_code, quantity, zip} = this.state
      this.setState({loading: true})
      if(country_code === 'AU'){
        return fetch(ausPostSeviceURL, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
          body: urlEncodeData({service: 'domestic_services', data: JSON.stringify({country_code, quantity, to_postcode: zip})})
        }).then(r => r.json())
        .then(r => {
          if(r.error)
            throw r
          this.setState({services: r.services.service}, update)
        })
        .catch((e) => {
          if(e.error && e.error.errorMessage){
            const msg = e.error.errorMessage
            if(msg === 'Please enter a valid To postcode.'){
              this.setState({formErrors: Object.assign({}, this.state.formErrors,{zip: 'invalid postcode'})})
            }
            throw {message: 'Failed to fetch domestic services'}
          }
        });
      }
      else {
        return fetch(ausPostSeviceURL, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
          body: urlEncodeData({service: 'international_services', data: JSON.stringify({country_code, quantity, to_postcode: zip})})
        }).then(r => r.json())
        .then(r => {
          this.setState({services: r.services.service}, update)
        })
        .catch((e) => {
          throw {message: 'Failed to fetch international services'}
        });
      }
    },
    updateService(service, update){
      var {serviceName, services} = this.state
      for (var i = 0; i < services.length; i++) {
        if(services[i].code === service){
          serviceName = services[i].name
          break
        }
      }
      this.setState({service, serviceName})
      this.calculatePostage(update).catch(e => this.appendError(e, true))
    },
    setState(obj, update){
      Object.assign(this.state, obj)
      if(update) {
        this.render()
        this.setState({loading: false})
        this.state.errors = []
      }
    },
    render(){
      const {showing, loading} = this.state
      if(loading)
        render(this.makeWaiting(), this.element)
      else if(showing === 'button')
        render(this.makeButton(), this.element)
      else if(showing === 'address')
        render(this.makeAddressForm(), this.element)
      else if(showing === 'paypal')
        render(this.makeFinalConfirm(), this.element)
    },
    async validateForm(event){
      event.preventDefault()
      this.setState({
        formErrors: {},
        errors: [],
        postage_result: {},
        loading: true
      }, true)
      const {countries} = this.state
      var formErrors = {}
      const form = new FormData(event.target);
      var amount = form.get('amount'),
      address1 = form.get('address1'),
      zip = form.get('zip'),
      city = form.get('city'),
      first_name = form.get('first_name'),
      last_name = form.get('last_name'),
      country_code = getCountryCodeFromList(form.get('country'), countries, 'NULL')
      if(!address1)
        formErrors['address1'] = 'Address required'
      if(!zip)
        formErrors['zip'] = 'Postcode required'
      if(!city)
        formErrors['city'] = 'City required'
      if(!first_name)
        formErrors['first_name'] = 'Firstname required'
      if(!last_name)
        formErrors['last_name'] = 'Lastname required'
      if(!country_code)
        formErrors['country_code'] = 'A valid country is required'
      this.setState({country_code, last_name, first_name, city, zip, address1});
      if(Object.keys(formErrors).length > 0){
        this.setState({formErrors}, true)
      }
      else {
        console.log('Preliminary Vilidation Passed..');
        this.setState({loading: true}, true)
        await this.updateServices().then(() => {
          return this.calculatePostage().then(() => {
            this.setState({showing: 'paypal', loading: false}, true)
          }).catch((e) => this.appendError({message: e.message || e}, true))
        }).catch((e) => this.appendError({message: e.message || e}, true))
      }
    },
    async updateQuantity(num){
      this.setState({quantity: num, loading: true})
      await this.calculatePostage()
      this.setState({loading: false}, true)
    },
    correctPostageServiceCode(){
      const {country_code, service} = this.state
      if(country_code == 'AU'){
        if(service && service.startsWith('AUS'))
          return service
        return DEFAULT_DOMESTIC_SERVICE
      }
      else {
        if(service && service.startsWith('INT'))
          return service
        return DEFAULT_INTERNATIONAL_SERVICE
      }
    },
    calculatePostage(update){
      const {country_code, zip, quantity} = this.state
      const action = country_code === 'AU'? 'calculate_domestic_postage': 'calculate_international_postage'
      const service_code = this.correctPostageServiceCode()
      this.setState({loading: true}, update)
      return fetch(ausPostSeviceURL, {
          method: 'POST',
          headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
          body: urlEncodeData({service: action, data: JSON.stringify({country_code, to_postcode: zip, service_code, quantity})})
      })
      .then(r => r.json())
      .then(r => {
        if(r.error){
          throw {message: r.error.errorMessage}
        }
        else{
          r.postage_result.total_cost = parseFloat(r.postage_result.total_cost)
          this.setState({postage_result: r.postage_result, loading: false}, update)
        }
      })
    },
    makeSuccess(){
      return html`
        <div id="success" class="font-large cursive">
          Thankyou For Buying The Book !
        </div>
      `
    },
    makeAddressForm(){
      const {country_code='', countries={}, city='', zip='', first_name='',
        last_name='', address1='', services, service='', formErrors,
        errors, country_name='', loading, initialized} = this.state
      //console.log({country_code, countries, services, service, formErrors, errors, country_name});
      if(!initialized)
        return this.makeWaiting()
      //console.log(errors);
      return html`
      <div class="mono font-medium">
        <h2>Shipping Details</h2>
        <div>
          ${errors.map(e => e? this.makeError(e.message || e): null)}
        </div>
        <form id="address-form" name="address-form" @submit=${(e) => this.validateForm(e)}>
          <div class="input-field">
            ${this.makeError(formErrors['first_name'])}
            <input type="text" name="first_name" placeholder="First Name" value="${first_name}" required/>
          </div>
          <div class="input-field">
            ${this.makeError(formErrors['last_name'])}
            <input type="text" name="last_name" placeholder="Last Name" value="${last_name}" required/>
          </div>
          <div class="input-field">
            ${this.makeError(formErrors['country_code'])}
            <input list="country-list" name="country" placeholder="Country"
              id="country" @change=${(e) => this.updateCountry(e.target.value)} value="${country_name}" required>
            <datalist id="country-list">
              ${Object.keys(countries).map(c => html`<option value="${countries[c].name}" ?selected=${c === country_code}></option>`)}
            </datalist>
          </div>
          <div class="input-field">
            ${this.makeError(formErrors['address1'])}
            <input type="text" name="address1" placeholder="Address" value="${address1}" required/>
          </div>
          <div class="input-field">
            ${this.makeError(formErrors['city'])}
            <input type="text" name="city" placeholder="City" value="${city}" required/>
          </div>
          <div class="input-field">
            ${this.makeError(formErrors['zip'])}
            <input type="text" name="zip" placeholder="Post Code" value="${zip}" required/>
          </div>
          <div class="input-field">
           ${ loading? this.makeWaiting()
              : html`<button form="address-form" type="submit" class="submit-form-button">Ok</button>`}
          </div>
          <div class="input-field">
            <button type="button" class="submit-form-button" @click=${(e) => {e.preventDefault(); this.setState({showing: 'button'}, true)}}>Cancel</button>
          </div>
        </form>
      </div>
      `
    },
    makeError(error){
      if(!error)
        return null
      return html`
        <div class="form-error">${error}</div>
      `
    },
    makeWaiting(){
      return html`
        <div style="width: 30px; margin: 0px auto;">${BarLoader()}</div>
      `
    },
    makePriceTable({bookPrice, postagePrice, totalPrice}){
      const {postage_result, country, language_code, quantity, currency_code} = this.state
      if(!postage_result.total_cost)
        return null
      const {currency} = country
      return html`
        <table class="price-table">
          <tr><td>Book Price (x ${quantity})</td><td>${this.formatPrice(bookPrice, currency, language_code)}</td></tr>
          <tr style="border-bottom: 1px solid grey"><td>Postage</td><td>${this.formatPrice(postagePrice, currency, language_code)}</td></tr>
          <tr><td><strong>Total</strong></td><td><strong>${currency_code + ' '}${this.formatPrice(totalPrice, currency, language_code)}</strong></td></tr>
        </table>
      `
    },
    makeAddressTable(){
      const {country_name, address1, zip, city, service, first_name, last_name} = this.state
      return html`
        <table class="address-table">
          <tr><td>First Name</td><td>${first_name}</td></tr>
          <tr><td>Last Name</td><td>${last_name}</td></tr>
          <tr><td>Country</td><td>${country_name}</td></tr>
          <tr><td>City</td><td>${city}</td></tr>
          <tr><td>Address</td><td>${address1}</td></tr>
          <tr><td>Postcode</td><td>${zip}</td></tr>
          <tr><td>&nbsp;</td><td class="text-right">
            <a class="control-link" @click=${(e) => {e.preventDefault(); this.setState({showing: 'address'}, true)}}>edit</a>
            <a class="control-link" @click=${(e) => {e.preventDefault(); this.setState({showing: 'button'}, true)}}>cancel</a>
            </td></tr>
        </table>
      `
    },
    convertCurrency(amount, exchange_rate){
      return parseFloat(amount * exchange_rate).toFixed(2);
    },
    makeButton(){
      return html`
        ${window.location.hash === '#success'? this.makeSuccess(): null}
        <button class="big-buy-button zoh cursive" @click=${(e) => {this.setState({showing: 'address'}, true); this.initState()}}>
          Buy Book
        </button>`
    },
    makeServiceQuantityPanel(){
      const {services, quantity, formErrors, service} = this.state
      return html`
    <div style="margin-bottom: 40px; ">
      <div style="display: flex; flex-direction: row; font-family: Arial;">
        <div class="input-field" style="flex: 0 0 40px; margin-right:10px;">
          <label>Quantity</label>
          <input type="number" min="1" max="10" value="${quantity}" @change=${(e) => this.updateQuantity(e.target.value)}/>
        </div>
        <div class="input-field" style="flex: 1 1 100px">
          ${this.makeError(formErrors['service'])}
          <label>Service</label>
          <select name="service" placeholder="Service" @change="${(e) => this.updateService(e.target.value, true)}" required>
            ${services.filter(s => ['Standard', 'Express', 'Express Post', 'Parcel Post'].includes(s.name)).map(s => html`<option value="${s.code}" ?selected=${s.code === service}>${s.name}</option>`)}
          </select>
        </div>
      </div>
      <p class="note font-small">
        For larger bulk orders please contact Archer and Trevor
        on this <a class="link" href="#email-address">email</a> address.
      </p>
    </div>
    `
    },
    makePaypalButton(){
      const {country_code, currency_code, country_name, amount, address1, zip,
        city, service, first_name, last_name, country, quantity, serviceName} = this.state
      const {bookPrice, postagePrice, totalPrice} = this.getPricing()
      const {width_x, length_x, height_x, weight} = this.bookSpecs

      return html`
        ${this.makePriceTable({bookPrice, postagePrice, totalPrice})}
        <paypal-button
          amount="${amount}"
          quantity="${quantity}"
          country="${country_code}",
          currency_code="${currency_code}"
          address1="${address1}"
          zip="${zip}"
          city="${city}"
          custom=" (${serviceName})"
          first_name="${first_name}"
          last_name="${last_name}"
          shipping="${postagePrice}"
          return="${window.location.href}#success"
          width_x="${width_x * quantity}"
          length_x="${height_x}"
          height_x="${length_x}"
          shipping_method="SHIPPING SERVICE: ${service}"
          weight="${(weight * quantity).toFixed(2)}"
        >
        </paypal-button>
      `
    },
    makeFinalConfirm(){
      const {loading, formErrors, errors, quantity} = this.state
      //console.log({country_code, country_name, amount, address1, zip, city, service, first_name, last_name, country});
      return html`
    <div class="mono font-medium">
      <h2>Buy Book</h2>
      <div>
        ${errors.map(e => e? this.makeError(e.message || e): null)}
      </div>
      ${this.makeAddressTable()}
      ${this.makeServiceQuantityPanel()}
      ${loading? this.makeWaiting(): this.makePaypalButton()}
    </div>
      `
    },
    getPricing(){
      const {amount, exchage_rate, postage_result, quantity} = this.state
      const total = amount * quantity
      return {
        bookPrice: this.convertCurrency(total, exchage_rate),
        postagePrice: this.convertCurrency(postage_result.total_cost, exchage_rate),
        totalPrice: this.convertCurrency(postage_result.total_cost + total, exchage_rate)
      }
    },
    formatPrice(amount, currency_code, language_code){
      if(!window.Intl)
        return amount
      try {
        var intl = new Intl.NumberFormat(language_code, { style: 'currency', currency: currency_code })
        return intl.format(amount)
      }
      catch(err){
        this.appendError({message: 'failed to format currency ' +  currency_code})
      }
      return amount
    }

}


function getCountryCode(){
  return window.navigator && window.navigator.language?
    window.navigator.language.split('-').pop(): 'AU'
}

function toFormData(data){
  const formData  = new FormData();
  for(const name in data) {
    formData.append(name, data[name]);
  }
  return formData
}

function urlEncodeData(data){
  return Object.entries(data).map(([k,v])=>{return k+'='+encodeURIComponent(v)}).join('&') // in jQuery simply use $.param(creds)
}

function getCountryName(){
  if(!window.Intl || (!window.navigator || !window.navigator.language))
    return ''
  const [lan, code] = window.navigator.language.split('-')
  var intl = new Intl.DisplayNames([lan], {type: 'region'});
  var name = intl.of(code)
  return name
}
function getCountryCodeFromList(name, countries, def){
  for(c in countries){
    if(countries[c].name === name)
      return c
  }
  return def
}
function getFirstCurrency(ar){
  ar.reverse()
  return ar.pop()
}
export default BuyBook
