import {html} from 'lit-html'
const defaults = {
  style:'vertical-align: middle; fill: currentColor;',
  dimensions:{x:32,y:32},
  viewBox: '0 0 16 16',
  className: 'bi'
}
function VolumeSVG(options) {
  const {style, dimensions, viewBox, className} = Object.assign({}, defaults, {viewBox: '0 0 512 512'}, options || {})
  const {x, y} = dimensions
  return html`<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}" width="${x}" height="${y}" class="${className}"><path d="M405.5 256c0 22.717-4.883 44.362-13.603 63.855l31.88 31.88C439.283 323.33 448 290.653 448 256c0-93.256-64-172.254-149-192v44.978C361 127.632 405.5 186.882 405.5 256zM256 80.458l-51.021 52.48L256 183.957zM420.842 396.885L91.116 67.157l-24 24 90.499 90.413-8.28 10.43H64v128h85.334L256 431.543V280l94.915 94.686C335.795 387.443 318 397.213 299 403.022V448c31-7.172 58.996-22.163 82.315-42.809l39.61 39.693 24-24.043-24.002-24.039-.081.083z"/><path d="M352.188 256c0-38.399-21.188-72.407-53.188-88.863v59.82l50.801 50.801A100.596 100.596 0 0 0 352.188 256z"/></svg>`
}
function VolumeSlashSVG(options){
  const {style, dimensions, viewBox, className} = Object.assign({}, defaults, {viewBox: '0 0 512 512'}, options || {})
  const {x, y} = dimensions
  return html`<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}" width="${x}" height="${y}" class="${className}"><path d="M64 192v128h85.334L256 431.543V80.458L149.334 192H64zm288 64c0-38.399-21.333-72.407-53.333-88.863v176.636C330.667 328.408 352 294.4 352 256zM298.667 64v44.978C360.531 127.632 405.334 186.882 405.334 256c0 69.119-44.803 128.369-106.667 147.022V448C384 428.254 448 349.257 448 256c0-93.256-64-172.254-149.333-192z"/></svg>`
}
function LetterSVG(options){
  const {style, dimensions, viewBox, className} = Object.assign({}, defaults, options || {})
  const {x, y} = dimensions
  return html`<svg style="${style}" xmlns="http://www.w3.org/2000/svg" width="${x}" height="${y}" fill="currentColor" class="${className}" viewBox="${viewBox}">
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
      </svg>`
}
function BarLoader(options){
  const {style, dimensions, viewBox, className} = Object.assign({}, defaults, {viewBox: '0 0 24 30', dimensions: {x:'24', y: '30'}}, options || {})
  const {x, y} = dimensions
  return html`
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="${x}px" height="${y}px" viewBox="${viewBox}" class="${className}" style="enable-background:new 0 0 50 50;" xml:space="preserve">
    <rect x="0" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="10" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.2s" dur="0.6s" repeatCount="indefinite" />
    </rect>
    <rect x="20" y="0" width="4" height="10" fill="#333">
      <animateTransform attributeType="xml"
        attributeName="transform" type="translate"
        values="0 0; 0 20; 0 0"
        begin="0.4s" dur="0.6s" repeatCount="indefinite" />
    </rect>
  </svg>
  `
}
export { LetterSVG, VolumeSlashSVG, VolumeSVG, BarLoader }
