export default [
  {
    selector: '.in-from-left',
    onIn: function(el){
      //console.log('IN .in-from-left');
      el.style.transform = 'translateX(0px)'
      el.style.opacity = '1.0'
    },
    onOut: function(el){
      //console.log('OUT .in-from-left');
      el.style.transform = 'translateX(-100px)'
      el.style.opacity = '0.0'
    },
    onInit: function(el){
      //console.log('INIT .in-from-left');
      el.style.transform = 'translateX(-100px)'
      el.style.transition = 'transform 1.5s cubic-bezier(0,0,0,1.2), opacity 1.8s ease-out'
      el.style.opacity = '0.0'
    },
    minWidth: 1200
  },
  {
    selector: '.in-from-right',
    onIn: function(el){
      //console.log('IN .in-from-right');
      el.style.transform = 'translateX(0px)'
      el.style.opacity = '1.0'
    },
    onOut: function(el){
      //console.log('OUT .in-from-right');
      el.style.transform = 'translateX(100px)'
      el.style.opacity = '0.0'
    },
    onInit: function(el){
      //console.log('INIT .in-from-right');
      el.style.transform = 'translateX(100px)'
      el.style.transition = 'transform 1.5s cubic-bezier(0,0,0,1.2), opacity 1.8s ease-out'
      el.style.opacity = '0.0'
    },
    minWidth: 1200
  },
  {
    selector: '.in-from-top',
    onIn: function(el){
      //console.log('IN .in-from-top')
      el.style.transform = 'translateY(0px)'
      el.style.opacity = '1.0'
    },
    onOut: function(el){
      //console.log('OUT .in-from-right')
      el.style.transform = 'translateY(-100px)'
      el.style.opacity = '0.0'
    },
    onInit: function(el){
      //console.log('INIT .in-from-right')
      el.style.transform = 'translateY(-100px)'
      el.style.transition = 'transform 1.5s cubic-bezier(0,0,0,1.2), opacity 1.8s ease-out'
      el.style.opacity = '0.0'
    },
    minWidth: 1200
  },
  {
    selector: '.in-from-bottom',
    onIn: function(el){
      //console.log('IN .in-from-top')
      el.style.transform = 'translateY(0px)'
      el.style.opacity = '1.0'
    },
    onOut: function(el){
      //console.log('OUT .in-from-right')
      el.style.transform = 'translateY(100px)'
      el.style.opacity = '0.0'
    },
    onInit: function(el){
      //console.log('INIT .in-from-right')
      el.style.transform = 'translateY(100px)'
      el.style.transition = 'transform 1.5s cubic-bezier(0,0,0,1.2), opacity 1.8s ease-out'
      el.style.opacity = '0.0'
    },
    minWidth: 1200
  }
];
