import HomePage from './page-home'
import ImageThumbs from './image-thumbs'
import PaypalButton from './paypal-button'
import BuyBook from './buy-book'
import PaypalElement from './paypal-element'
const components = {
  HomePage,
  ImageThumbs,
  PaypalButton,
  BuyBook,
  PaypalElement
}
export default components
