import {render, html} from 'lit-html'
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
import './css/image-thumbs.css'
//const html = String.raw;
const ImageThumbs = {
    tagname: 'image-thumbs',
    observedAttributes: ['list', 'imgdir'],
    attributeChanged(name, oldValue, newValue) {},
    init(){
      this.props = {
        list: this.element.getAttribute('list'),
        imgdir: this.element.getAttribute('imgdir')
      }
      this.state = {
        index: 0,
        overlay: false,
        origin: window._origin || window.location.origin
      }
    },
    setState(obj, ren){
      this.state = Object.assign(this.state, obj)
      if(ren){
        this.render()
      }
    },
    setOverlayOpen(open){
      if(open){
        this.setState({overlay: true})
        document.body.classList.add('noscroll')
      }
      else {
        this.setState({overlay: false})
        document.body.classList.remove('noscroll')
      }
      this.render()
    },
    async connected() {
      var {list} = this.props
      // check window._href for domino ssr
      var origin = this.state.origin
      var images = await fetch(origin + list).then((r) => r.json())
      this.props.images = images
      this.render()
    },
    render(){
      var {images, imgdir} = this.props;
      var {index, overlay} = this.state
      render( html`
${overlay? this.makeOverlay(index): null}
<div class="book-thumbs-wrapper">
  ${this.makeThumbs(images, imgdir)}
</div>
      `, this.element)
    },
    thumbToImage(thumb){
      var {imgdir} = this.props
      return `${imgdir}/${thumb.replace(/250x/, '1000x')}`
    },
    makeOverlay(index){
      var {images} = this.props
      var total = images.length
      var {nextUrl, prevUrl, currentUrl, thumbUrl} = this.makeUrls()
      return  html`
  <div class="thumb-overlay">
    <div class="close-thumb-overlay" @click="${() => this.setOverlayOpen(false)}">✖</div>
    <div class="thumb-nav">
      <div @click="${() => this.setState({index: (total - 1 + index) % total}, true)}">❮</div>
      <div @click="${() => this.setState({index: (total + 1 + index) % total}, true)}">❯</div>
    </div>
    <div class="thumb-overlay-inner">
      ${unsafeHTML(`<div class="overlay-image-wrapper imagit blur" data-src="${currentUrl}" style="background-image: url(${thumbUrl});">
        <img src="${currentUrl}" style="visibility: hidden;"/>
        <img src="${prevUrl}" style="display: none;"/>
        <img src="${nextUrl}" style="display: none;"/>
      </div>`)}
    <div>
  </div>`
    },
    makeThumbs(images, imgdir){
      return images.map(im => {
        return html`<div class="book-thumb" @click="${() => this.showOverlay(im)}">
          <img src="${imgdir}/${im}"/>
        </div>`
      })
    },
    showOverlay(im){
      var {images} = this.props
      var index = images.indexOf(im)
      this.setState({index})
      console.log(index, im);
      this.setOverlayOpen(true)
    },
    makeUrls(){
      var {images, imgdir} = this.props
      var {index, origin} = this.state
      var total = images.length
      var next = (total + 1 + index) % total
      var prev = (total - 1 + index) % total
      var thumbUrl = `${imgdir}/${images[index]}`
      var currentUrl = this.thumbToImage(images[index])
      var nextUrl = this.thumbToImage(images[next])
      var prevUrl = this.thumbToImage(images[prev])
      return {nextUrl, prevUrl, currentUrl, thumbUrl}
    }
}

export default ImageThumbs
