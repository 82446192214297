import {qs, qa, join} from './helpers'
import Router from './router'
import Connector from './connector'
export default async function App({mount, routesUrl, onRouteChange}){
  let element =  qs(mount)
  let connector = Connector({element, type:'page', onRouteChange})
  let routes = await fetch(join(window.location.origin, routesUrl))
    .then(r => r.json())
  let router = Router({routes, connector})
  router.listen()
  return router
}
