class Imagator {
  constructor({imageClass, start, node}){
    this.imageClass = '.imagit' ||  imageClass
    if(start){
      this.start(node || document.body)
    }
  }
  start(node){
    const imageClass = this.imageClass
    let observer = new MutationObserver((records) => {
      records.forEach(record => {
        if(!record.target.querySelectorAll)
          return
        record.target.querySelectorAll(imageClass).forEach((element) => {
          loadImage(element, () => {
            element.classList.remove(imageClass.replace('.', ''))
            element.classList.remove('blur')
          })
        })
      })
    });
    observer.observe(node, {
        childList: true,
        subtree: true,
        characterDataOldValue: true
    });
  }
}

function loadImage(item, onLoad){
  // Start loading image
    const img = new Image();
    img.src = item.dataset.src;
    img.onload = () => {
      onLoad && onLoad(item)
      return item.nodeName === 'IMG' ?
        item.src = item.dataset.src :
        item.style.backgroundImage = `url(${item.dataset.src})`;
    };
}

export {loadImage, Imagator}
