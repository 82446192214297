import {render, html} from 'lit-html';
import {LetterSVG} from '../app/src/svg'
import  './css/page-home.css';
import {paypalClientID} from '../app/settings'
const HomePage = {
    tagname: 'page-home',
    observedAttributes: ['images'],
    init(){
      this.props = {
        images: JSON.parse(this.element.getAttribute('images').replace(RegExp("'", 'g'), '"'))
      }
      this.state = {
        vidLoaded: false
      }
      // window.addEventListener('scroll', () => {
      //   var v = document.querySelector('#banner-vid')
      //   console.log('scrolling', v);
      //   if(v && v.currentTime == 0.0 )
      //     v.play();
      // })
    },
    attributeChanged(name, oldValue, newValue) {},
    connected() {
      if(this.element.children.length === 0){
        this.render()
      }
    },
    render() {
      console.log('rendering home');
      var {images} = this.props
      const {vidLoaded} = this.state
      render(html`
<div class="fade-in">
  <div style="overflow: hidden;"
      class=""
      data-rellax-mobile-speed="0"
      data-rellax-tablet-speed="0"
      data-rellax-xs-speed="0"
      data-rellax-desktop-speed="-1">

    <div id="banner-img" class="banner-img-wrap imagit"
      data-src="${images.bannerimg}"
      style="background-image: url('${images.bannerthumb}');"
    >
        <img style="width: 100%;" src="${images.bannerthumb}"/>
    </div>
  </div>

  <div class="front-mid-pane" style="margin-top: -2px;">
    <div class="front-mid-inner">
      <section class="content-section w-100" style="padding: 100px 0px; display: flex; justify-content: center;flex-direction: row;">
        <div class="" style="flex: 1 1 400px; max-width: 590px;">
        <!-- <button class="big-buy-button zoh in-from-bottom">Book Coming Soon !</button> -->
        <buy-book amount="35.00"></buy-book>
        </div>
      </section>
    </div>
  </div>

  <div class="front-image-pane">
    <div class="front-image mlax imagit"
      data-src="${images.img1}"
      style="background-image: url('${images.img1thumb}');"
     ></div>
  </div>

  <div class="front-mid-pane" style="margin-top: -2px;">
    <div class="front-mid-inner ">
      <section class="in-from-right w-100 cursive">
        <div style="max-width: 800px; margin: 0px auto;">
          <h1>Preview</h1>
          <div class="vid-wrapper">
            <iframe src="https://www.youtube.com/embed/fhL7brwvhJo"></iframe>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div class="front-image-pane">
    <div class="front-image mlax imagit"
      data-src="${images.img2}"
      style="background-image: url('${images.img2thumb}');"
     ></div>
  </div>

  <div class="front-mid-pane">
    <div class="front-mid-inner ">
      <section class="content-section w-100">
        <div class="center w-500px in-from-right cursive">
          <h1>About</h1>
<p>
  <b>Archer is:</b><br/> The ill-fated explorer of the interior recesses of the embattled brain-box and beyond
</p>
<p>
  <b>Trevva is:</b><br/> The Mystical Enchanter
</p>
<p>
  <b>Together they done wrote:</b><br/> Archer and Trevvas Book of Ideas
</p>
<p>
  A book of Hope

  and of terrible calamities

  Written for the people

  And themselves

  And for  all of the everythings

  Yors sincerely

  Archer and Trevva

  Lovingly dedicated to Trevvas Dear Mum
</p>
        </div>
      </section>
    </div>
  </div>

  <div class="front-image-pane">
    <div class="front-image mlax imagit"
      data-src="${images.img3}"
      style="background-image: url('${images.img3thumb}');"
     ></div>
  </div>
  <div class="front-mid-pane">
    <div class="front-mid-inner ">
      <section class="in-from-bottom w-100 text-center cursive">
        <h1>Contact</h1>
        ${Contact(images.email)}
      </section>
    </div>
  </div>
</div>
      `, this.element)
    },

};
function Contact(email){
  return html`
    <div id="email-address" class="contact-pane">
      ${LetterSVG()}
      <a style="padding-left: 0.5em; overflow-wrap: anywhere;" href="mailto:${email}">${email}</a>
   </div>
  `
}
export default HomePage
