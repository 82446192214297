import {render, html} from 'lit-html'
import {paypalClientID, ausPostToken} from '../app/settings'
const PaypalElement = {
    tagname: 'paypal-element',
    observedAttributes: [
      'amount', 'address1', 'zip', 'city', 'country','first_name', 'last_name',
      'email', 'custom', 'shipping', 'currency_code', 'weight', 'height',
      'length', 'width', 'return', 'quantity', 'shipping_method'],
    init(){
      this.details = {}
      Array.from(this.element.attributes).forEach(a => {
        this.details[a.name] = a.value
      })
    },
    attributeChanged(name, oldValue, newValue) {
      this.details[name] = newValue
      this.render()
    },
    connected(){
      this.render()
    },
    render(){
      render(html`
    <form action="https://www.sandbox.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_xclick">
        <input type="hidden" name="business" value="LNMQX27WADP8G">
        <input type="hidden" name="lc" value="AU">
        <input type="hidden" name="item_name" value="Archer And Trevas Book Of Ideas">
        <input type="hidden" name="item_number" value="atbi">
        <input type="hidden" name="amount" value="30.00">
        <input type="hidden" name="currency_code" value="AUD">
        <input type="hidden" name="button_subtype" value="services">
        <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynowCC_LG.gif:NonHosted">
        <input type="image" src="https://www.paypalobjects.com/en_AU/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!">
        <img alt="" border="0" src="https://www.paypalobjects.com/en_AU/i/scr/pixel.gif" width="1" height="1">
    </form>

`, this.element)
    }
}

export default PaypalElement
