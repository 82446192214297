const origin = window.location.origin || window._origin

function join(){
  var arr = Array.from(arguments)
  var str = arr[0]
  var s = '';
  arr.shift()
  arr.forEach(a => {
      s = a.replace(/^\/+/mg, '').replace(/:\/+/, ':/')
      str = str + '/' + s
  })
  return str
}
function setMeta(meta, title){
  var {description, keywords, image} = meta
  var el = qs('meta[name="description"]')
  if(description && el)
    el.setAttribute('content', description)
  el = qs('meta[name="keywords"]')
  if(keywords && el)
    el.setAttribute('content', keywords)
  el = qs('meta[name="image"]')
  if(image && el)
    el.setAttribute('content', image)
  el = qs('head > title')
  if(title && el)
    el.innerHTML = meta.title || title
}
function qs(sel){
  return document.querySelector(sel) || document.createElement('div')
}
function qa(sel){
  return document.querySelectorAll(sel) || document.createElement('div')
}
function getAttrs(element){
  var attrs = {}
  Array.from(this.element.attributes).forEach(a => {
    attrs[a.name] = a.value
  })
  return attrs
}
export {qs, qa, join, setMeta, getAttrs, origin}
